import _ from 'underscore';
import $ from 'jquery';
import Backbone from 'backbone';
import { api, listings as listingsColl, config, rules } from 'BoomTown';
import * as scrollspy from 'utility/scrollspy';

import { isEAlertResults } from 'selectors/router';
import Listing from 'backbone/Model/listing';

import ListingCardView from 'legacy/Views/Listings/card';
import viewTemplate from 'templates/listings/gallery/base.hbs';
import noResultsTemplate from 'templates/listings/noresults/base.hbs';
import sellerAdTemplate from 'templates/listings/seller/gallery.hbs';
import saveSearchAdTemplate from 'templates/listings/save-search-ad/gallery.hbs';
import loadMoreTemplate from 'templates/listings/gallery/loadMoreButton.hbs';
import footerTemplate from 'templates/listings/gallery/galleryFooter.hbs';
import { GALLERY_SELECTORS } from 'cypress_constants';

import { clickSaveSearchCard, clickSaveSearchInNoResults } from './galleryActions';
import ResultSet from './resultSet';

/**
 * Helper added by `decaffeinate` to implement CS null-coalescing operator.
 */
function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}

export default class GalleryView extends Backbone.View {
  get events() {
    return {
      'click .js-gallery-loadmore': 'fetchNextPage',
      'click .js-bt-save-search-ad__cta': 'saveSearch',
      'click .js-save-search': 'noResultsSaveSearch',
    };
  }

  initialize() {
    this.handleListingsIsLoadingChange = this.handleListingsIsLoadingChange.bind(this);

    this.attributes = {
      class: ['listings_view_gallery', 'at-gallery-view'],
    };
    this.el.classList.add(...this.attributes.class);
    this.childViews = [];
    this.$noResults = $(noResultsTemplate());
    this.sellerAdHTML = sellerAdTemplate({
      ImgDir: window.bt.globalVars.ImgDir,
      noValuationText: rules.get('NoValuationVerbiage'),
    });
    this.saveSearchAdHTML = saveSearchAdTemplate();
    this.pageCount = __guard__(listingsColl, x => x.PageCount) || config.pageCount;
    // CNS-'MaxGalleryResults' gets higher priority for the Gallery view
    // @see CNS-936
    this.maxresults = rules.get('MaxGalleryResults') || rules.get('MaxListingResults') || Infinity;

    /** @type {HTMLElement} */
    this.loadMoreButton = null;

    /**
     * We hold onto any inflight ajax so we can abort them if necessary
     * @type {JQueryXHR?}
     */
    this._inflightNextPage = null;

    this.listings = new ResultSet(listingsColl);

    this.listenTo(this.listings, 'update', this.addListings);

    this.listenTo(window.bt.search, 'change', () => {
      this.unbindScrollSpy(); // Will be a no-op if not bound.
    });

    // We're placing this state here because adding listings to the collection
    // is something that we do from the outside, and it's only used in this
    // results view.
    this.state = new Backbone.Model({
      isLoadingNextPage: false,
    });

    listingsColl.state.on('change:isLoading', this.handleListingsIsLoadingChange);

    // Fetching the next page of data
    this.state.on('change:isLoadingNextPage', ({ attributes: state }) => {
      this.unbindScrollSpy();

      /** Add registered trademark for BoardID 160.
       * {@see https://jira.boomtownroi.com/browse/CNS-8037}
       */
      const mlsText = config.boards[160] ? 'MLS\u00AE' : 'MLS';

      const isLoading = state.isLoadingNextPage;
      const pastMaxResults = this.listings.length >= this.maxresults;
      const areMoreListings = listingsColl.TotalItems > this.listings.length;
      this.$('.js-load-more-wrapper').html(
        loadMoreTemplate({
          isLoading,
          pastMaxResults,
          mlsText,
          haveListings: Boolean(this.listings.length),
          areMoreListings,
          maxResults: this.maxresults,
          cySelectors:  GALLERY_SELECTORS,
        })
      );

      if (!isLoading && !pastMaxResults && areMoreListings) {
        this.bindScrollSpy();
      }
    });
  }

  /**
   * Need to:
   * - Bind listing card views to server-rendered HTML
   * - Render the footer
   * - Possibly inject save search ad card
   * - Bind the scrollspy
   */
  bindToDOM() {
    this.childViews = this.$('.js-card')
      .map(
        (i, el) =>
          new ListingCardView({
            el,
            model: listingsColl.get(el.dataset.listingid),
            renderUI: false,
          })
      )
      .get();

    this.$('.js-gallery-footer').html(
      footerTemplate({
        isLoading: listingsColl.state.get('isLoading'),
        pastMaxResults: this.listings.length >= this.maxresults,
        haveListings: Boolean(this.listings.length),
        disclaimerHTML: window.bt.utility.allDataPagesDisclaimer(),
        areMoreListings: listingsColl.TotalItems > this.listings.length,
        cySelectors:  GALLERY_SELECTORS,
      })
    );

    this.injectSaveSearchAd();

    // This is needed under bindToDOM as well in case you just open and close search
    // it wouldn't count as a location change, and the html is still cached...
    this.restoreScrollPosition();

    this.bindScrollSpy();

    if (listingsColl.models) {
      window.bt.events.trigger('cardImpression', listingsColl.models);
    }
  }

  mount({ router }) {
    this.render();

    if (router.hash) {
      const el = document.getElementById(router.hash.replace('#', ''));
      if (!el) {
        return;
      }

      /* eslint-disable no-mixed-operators */
      const middleOfCard = el.clientHeight / 2;
      const middleOfViewport = window.innerHeight / 2;
      const yCentered = el.offsetTop + middleOfCard - middleOfViewport;
      window.scrollTo(0, yCentered);
      /* eslint-enable no-mixed-operators */
    }

    this.restoreScrollPosition();
  }

  restoreScrollPosition() {
    // REFACTOR: When this component is connected we should do this in mapStateToProps
    // eslint-disable-next-line
    const store = require('store').default;
    const scrollDepth = store.getState().gallery.scrollY;
    if (scrollDepth > -1) {
      window.scrollTo(0, scrollDepth);
    }
  }

  /**
   * Render the entire view for client-side route transitions.
   */
  render() {
    // Header state
    const title = rules.get('ShouldShowVillas') ?
      listingsColl.getTitle() :
      listingsColl.getTitle().replace('Villas & ', '');
    const updateAgos = _.values(config.boards);
    let recent = true;
    if (updateAgos[0].LastUpdatedAgo > 120) {
      recent = false;
    }
    const resultsPaging = `${window.bt.utility.addCommas(listingsColl.TotalItems)} `;
    const headerData = {
      resultsPaging,
      title,
      recentlyUpdated: recent,
      lastUpdate: updateAgos[0].LastUpdatedAgo,
    };

    // If we are about to render more listings that the MLS says we should, slice the array
    // and add state to show warning. TODO: Determine if we should just scrap this, because
    // we're checking before fetching each subsequent page.
    const pastMaxResults = this.listings.length > this.maxresults;
    const listingModels = pastMaxResults
      ? [...this.listings].slice(0, this.maxresults)
      : [...this.listings];

    const templateData = Object.assign(
      {
        listings: listingModels.map(m => m.toJSON()),
        // This is only to avoid writing a hbs helper
        haveListings: Boolean(this.listings.length),
        isLoading: listingsColl.state.get('isLoading'),
        isFavoritesView: window.bt.search.has('favs'),
        areMoreListings: listingsColl.TotalItems > this.listings.length,
        pastMaxResults,
        maxResults: this.maxresults,
        disclaimerHTML: window.bt.utility.allDataPagesDisclaimer(),
      },
      headerData
    );

    // Render the gallery template, which doesn't include partials for the
    // cards, and then insert the DOM rendered by each of the card views into
    // the resulting HTML
    const layoutHTML = viewTemplate(templateData);

    // `isLoading` means the listings coll. has been invalidated; don't
    // re-render previous listings' cards.
    if (templateData.isLoading || !listingModels.length) {
      this.$el.html(layoutHTML);
    } else {
      this.childViews = listingModels.map(l => {
        l.beefUp();
        return new ListingCardView({
          model: l,
        });
      });

      const cardEls = this.childViews.map(v => {
        const el = document.createElement('div');
        el.classList.add('cell');
        el.appendChild(v.el);
        return el;
      });

      // The `sellerAds` config is the position where the ad should be placed.
      // Note that this is a nullable number, set to `null` when no seller ad
      // should be displayed.
      const { sellerAds: sellerAdIndex } = config;
      if (
        typeof sellerAdIndex === 'number' &&
        sellerAdIndex >= 0 &&
        !window.bt.search.has('favs')
      ) {
        const ad = document.createElement('div');
        ad.classList.add('cell');
        ad.innerHTML = this.sellerAdHTML;
        cardEls.splice(sellerAdIndex, 0, ad);
      }

      const $layoutHTML = $(layoutHTML);
      $layoutHTML.find('.js-load-results').append(cardEls);
      this.$el.html($layoutHTML);

      // Send card impressions to GTM
      if (listingModels) {
        window.bt.events.trigger('cardImpressions', listingModels);
      }

      this.bindScrollSpy();

      // Now that `mount()` is being called from React's `componentDidMount()`,
      // (via `backboneToReactView()`) at this point the new DOM nodes seem to
      // have been written to the DOM, but the previous components' nodes have
      // not been removed yet, which throws off the "inViewport" calculations.
      // TODO: Investigate this more. We might be able to adjust the way we're
      // interoperating with the React API to get rid of this behavior.
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          window.bt.lazyload.refresh();
        });
      });
    }

    // Possibly inject save search ad card
    this.injectSaveSearchAd();
  }

  /**
   * Listings collection's 'change:isLoading' event handler. Emitted when a new
   * result set has been returned. (Note: This method is bound to the instance
   * in `initialize()`. An arrow function property would not be defined until
   * after the ctor is run)
   */
  handleListingsIsLoadingChange() {
    // If our base listing collection, and search criteria, are changing then we don't care about
    // these results anymore
    this.cancelInflightAJAX();

    // TODO: CNS-3968 on a new result set we take the pageCount from the
    // api response this might not be the intended repercussions, but we
    // are trying to maintain parity with production
    this.pageCount = window.bt.listings.PageCount;
    this.childViews.forEach(v => v.remove());
    this.childViews = [];
    this.render();
  }

  /**
   * Called on route transitions that don't result in this view being
   * mounted or unmounted.
   * NOTE: We could use the search change event for this as well. Analogous to
   * the Redux store vs. router props dilemma.
   */
  update() {}

  viewWillUnmount() {
    this.cancelInflightAJAX();
    this.unbindScrollSpy();
    this.el.classList.remove(...this.attributes.class);
    this.childViews.forEach(v => v.remove());
    listingsColl.state.off('change:isLoading', this.handleListingsIsLoadingChange);
    this.listings.removeListeners();
  }

  /**
   * Render additional listings. Bound to the listings collection 'update' event.
   */
  addListings(listingModels) {
    const cardViews = listingModels.map(l => {
      l.beefUp();
      return new ListingCardView({
        model: l,
      });
    });
    this.$('.js-load-results').append(
      cardViews.map(v => {
        const el = document.createElement('div');
        el.classList.add('cell');
        el.appendChild(v.el);
        return el;
      })
    );
    this.childViews.push(...cardViews);
    window.bt.lazyload.refresh();

    // Possibly inject save search ad card
    this.injectSaveSearchAd();
  }

  /**
   * Bind the load more button scrollspy event listener and store a ref. to
   * the element on the view.
   */
  bindScrollSpy() {
    const scrollSpyYOffset = 500;
    const htmlElement = this.$('.js-gallery-loadmore')[0];
    scrollspy.addScrollspyListener(htmlElement, this.onScrollSpy, { x: 0, y: scrollSpyYOffset });
    this.loadMoreButton = htmlElement;
  }

  unbindScrollSpy() {
    scrollspy.removeScrollspyListener(this.loadMoreButton, this.onScrollSpy);
    this.loadMoreButton = null;
  }

  onScrollSpy = () => {
    if (
      !this.state.get('isLoadingNextPage') &&
      (listingsColl.PageIndex + 1) % 5 !== 0 &&
      this.listings.length > 8
    ) {
      this.fetchNextPage();
    }
  };

  /**
   * Scrollspy and click handler for the "Load More Results" button. We always
   * request the next page, but only add the portion of that next page that totals
   * up to the `maxresults` limit.
   */
  fetchNextPage() {
    this.state.set({
      isLoadingNextPage: true,
    });

    // In requesting the next page, always ask for the same amount.
    const totalWithNextPage = this.listings.length + listingsColl.PageCount;
    const truncateNextPage =
      this.maxresults < totalWithNextPage
        ? listingsColl.PageCount - (totalWithNextPage - this.maxresults)
        : null;

    const params = {
      ...window.bt.search.toJSON(),
      pageindex: listingsColl.PageIndex + 1,
      pagecount: this.pageCount,
    };

    this._inflightNextPage = api
      .ajaxsearch(null, params, () => {})
      .done(res => {
        listingsColl.applyMeta(res);
        const newListings = truncateNextPage
          ? res.Result.Items.slice(0, truncateNextPage)
          : res.Result.Items;

        this.listings.add(newListings.map(x => new Listing(x)));
      })
      .always(() => {
        this.state.set({
          isLoadingNextPage: false,
        });
        this._inflightNextPage = null;
      });
  }

  injectSaveSearchAd() {
    // eslint-disable-next-line
    const { getState } = require('store').default;
    const isLoading = listingsColl.state.get('isLoading');
    const areMoreListings = listingsColl.TotalItems > this.listings.length;
    if (
      listingsColl.TotalItems > 0 &&
      !isLoading &&
      !areMoreListings &&
      !isEAlertResults(getState())
    ) {
      const saveSearchAd = document.createElement('div');
      saveSearchAd.classList.add('cell');
      saveSearchAd.innerHTML = this.saveSearchAdHTML;
      this.$('.js-load-results').append(saveSearchAd);
    }
  }

  /**
   * @param {Event} e
   */
  saveSearch(e) {
    e.preventDefault();
    // eslint-disable-next-line
    const { dispatch } = require('store').default;
    dispatch(clickSaveSearchCard());
  }

  noResultsSaveSearch(e) {
    e.preventDefault();
    // eslint-disable-next-line
    const { dispatch } = require('store').default;
    dispatch(clickSaveSearchInNoResults());
  }

  cancelInflightAJAX() {
    if (this._inflightNextPage !== null) {
      this._inflightNextPage.abort();
    }
  }
}
