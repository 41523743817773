var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"bt-results__header__mls-data\">\n        <span\n            class=\"uk-text-small uk-text-muted\"\n            data-uk-tooltip title=\"Our MLS data is the freshest around which ensures the most accurate property information is available to you.\">\n            Updated <strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lastUpdate") || (depth0 != null ? lookupProperty(depth0,"lastUpdate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lastUpdate","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":42}}}) : helper)))
    + " minutes ago</strong>\n        </span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"m-0 text-lg--center bt-results-header__tagline\">\n    <span class=\"results-paging\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"resultsPaging") || (depth0 != null ? lookupProperty(depth0,"resultsPaging") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resultsPaging","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":50}}}) : helper)))
    + "</span>\n    <span class=\"results-count\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":41}}}) : helper)))
    + "</span>\n</h1>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recentlyUpdated") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"useData":true});