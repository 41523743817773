import Backbone from 'backbone';

/**
 * A small wrapper around Backbone.Collection to allow for duplicate models by
 * ID. Iterating on this object yields models found in the associated collection.
 */
class ResultSet {
  /**
   * @param {Backbone.Collection} coll
   */
  constructor(coll) {
    this.collection = coll;

    /**
     * A list of the IDs in the result set
     * @type {Array<string | number>}
     */
    this.resultList = coll.map(x => x.id);

    this.collection.on('reset', this.reset);
  }

  get length() {
    return this.resultList.length;
  }

  /**
   * Implement the iterator protocol so that we can do things like
   * `[...(new ResultSet(coll))].slice(0, max)`
   */
  [Symbol.iterator]() {
    function* generator() {
      yield* this.resultList.map(id => this.collection.get(id));
    }
    return generator.bind(this)();
  }

  /**
   * Add a group of models
   * @param {Backbone.Model[]} models
   */
  add(models) {
    this.resultList.push(...models.map(x => x.id));
    this.collection.add(models);
    this.trigger('update', models);
  }

  /**
   * Reset the result set to match the state of the collection.
   */
  reset = () => {
    this.resultList = this.collection.map(x => x.id);
  };

  /**
   * Remove any event listeners bound by this object.
   */
  removeListeners() {
    this.collection.off('reset', this.reset);
  }
}

// Mix in all of the Backbone.Events methods onto this class.
Object.assign(ResultSet.prototype, Backbone.Events);

export default ResultSet;
