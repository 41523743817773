/**
 * This is a custom scrollspy module for adding event listeners to the
 * 'scroll' event on the document, where each event listener is only called
 * when the associated element is in view. Since the listener will be
 * repeatedly called as long as the element is in view it's up to the
 * consumer to either unbind the listener, or provide some condition in the
 * listener if this behavior is not desirable.
 */

/**
 * @typedef {Object} ScrollListener
 * @prop {Function} listener
 * @prop {{ x: number, y: number }} offset
 */

/**
 * Determine if the element is fully in the current browser viewport.
 *
 * @param {HTMLElement} element
 * @param {{ x: number, y: number }} offset
 */
export function isInViewport(element, offset = { x: 0, y: 0 }) {
  if (!document.body.contains(element)) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  const html = document.documentElement;
  return (
    rect.top >= -1 * offset.y &&
    rect.left >= -1 * offset.x &&
    rect.bottom <= (window.innerHeight || html.clientHeight) + offset.y &&
    rect.right <= (window.innerWidth || html.clientWidth) + offset.x
  );
}

/**
 * The store of added scrollspy listeners. A map from the element being
 * observed to an array of event listeners.
 *
 * @type {Map<HTMLElement, Array<ScrollListener>>}
 */
const _listenerMap = new Map();

/**
 * Check all of the elements in `_listenerMap` and fire their event
 * listeners if in view.
 */
function check() {
  _listenerMap.forEach((listeners, el) => {
    listeners.forEach((x) => {
      if (isInViewport(el, x.offset)) {
        try {
          x.listener();
        } catch (e) {
          // Remove the listener and throw the exception.
          removeScrollspyListener(el, x);
          throw e;
        }
      }
    });
  });
}

// A semaphore to prevent more calls to rAF than one at a time.
let ticking = false;

/**
 * The listener added to the document 'scroll' event. This should be bound
 * and unbound depending on whether there any scrollspy listeners
 * registered.
 */
function scrollListener() {
  if (!ticking) {
    window.requestAnimationFrame(() => {
      check();
      ticking = false;
    });
    ticking = true;
  }
}

/**
 * Remove all entries that have elements (keys) which are not in the
 * document.body.
 */
function removeZombieElements() {
  _listenerMap.forEach((val, el) => {
    if (!document.body.contains(el)) {
      _listenerMap.delete(el);
    }
  });
}

/**
 * Be notified whenever the given element is in view.
 *
 * @param {HTMLElement} element
 * @param {function} fn
 * @param {{ x: number, y: number }} offset
 */
export function addScrollspyListener(element, fn, offset = { x: 0, y: 0 }) {
  // Safeguard against not re-adding the scroll listener due to zombie elements
  // in the map.
  removeZombieElements();
  if (_listenerMap.size === 0) {
    // Maybe use set timeout with a non-zero delay for less fine grained usage
    window.addEventListener('scroll', scrollListener);
  }

  // Add the listener to the map
  const listeners = _listenerMap.get(element) || [];
  listeners.push({ listener: fn, offset });
  _listenerMap.set(element, listeners);
}

export function removeScrollspyListener(element, fn) {
  const listeners = _listenerMap.get(element);
  if (listeners) {
    const i = listeners.findIndex(x => x.listener === fn);
    if (i === -1) {
      return;
    }
    listeners.splice(i, 1);
    if (!listeners.length) {
      _listenerMap.delete(element);
      window.removeEventListener('scroll', scrollListener);
    }
  }
}

/**
 * Be notified when an element in in the viewport, but only once.
 *
 * @param {HTMLElement} element
 * @param {Function} fn
 * @param {{ x: number, y: number }} offset
 */
export function addScrollspyOnce(element, fn, offset = { x: 0, y: 0 }) {
  function onceCb() {
    removeScrollspyListener(element, onceCb);
    fn();
  }
  addScrollspyListener(element, onceCb, offset);
}
