var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        Is It Time to Sell?\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        Thinking About Selling Your Home?\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        Find out with a FREE property report.\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        Find out how much your home is worth.\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        Get Your Report\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "        Get FREE Property Report\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bt-flex width-1-1\">\n  <div class=\"bt-seller-ad bt-seller-ad--gallery grid grid--center\">\n    <div class=\"cell bt-seller-ad--gallery__inner\">\n      <div class=\"bt-seller-ad__title\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noValuationText") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":9,"column":13}}})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"bt-seller-ad__subtext\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noValuationText") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "      </div>\n      <a role=\"button\" class=\"btn bt-seller-ad__btn btn--width-auto mt-16\" href=\"/home-valuation\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noValuationText") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":19,"column":6},"end":{"line":23,"column":13}}})) != null ? stack1 : "")
    + "      </a>\n    </div>\n  </div>\n</div>\n";
},"useData":true});