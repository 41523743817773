var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-load-more-wrapper\">\n"
    + ((stack1 = container.invokePartial(require("./loadMoreButton.hbs"),depth0,{"name":"listings/gallery/loadMoreButton","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n<div class=\"uk-text-small\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"disclaimerHTML") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n<div class=\"js-customscript-disclaimer\"></div>\n";
},"usePartial":true,"useData":true});